import * as yup from "yup";
const nameRegex = /^(?!.*\s{2,})[a-zA-Z]+(?: [a-zA-Z]+)?$/;
const phoneRegex = /^[2-9][0-9]{9}$/;
const paraRegex = /^(?!.* {2,})[\s\S]*[^\s]+[\s\S]*$/;

export const contactusFormSchema = yup.object({
	name: yup
		.string()
		.matches(nameRegex, "Invalid name format")
		.required("Required"),
	phone: yup
		.string()
		.matches(phoneRegex, "Phone number must be 10 digits and not start with 1")
		.required("Required"),
	email: yup
		.string("Enter your email")
		.email("Enter a valid email")
		.required("Required"),
	message: yup
		.string()
		.matches(paraRegex, "Invalid message format")
		.required("Required"),
	captcha: yup
		.string()
		.max(2, "Captcha should be in 2 digits")
		.required("Required"),
});

export const careerFormSchema = yup.object({
	firstName: yup
		.string()
		.matches(nameRegex, "Invalid name format")
		.required("First name is required"),
	lastName: yup
		.string()
		.matches(nameRegex, "Invalid name format")
		.required("Last name is required"),
	phone: yup
		.string()
		.matches(phoneRegex, "Phone number must be 10 digits and not start with 1")
		.required("Phone No. is required"),
	email: yup
		.string("Enter your email")
		.email("Enter a valid email")
		.required("Email is required"),
	position: yup.string().required("Choose any position"),
	skills: yup
		.array()
		.min(1, "At least one skill is required")
		.required("Skills is required"),
	resume: yup.string().required("Resume is required"),
});

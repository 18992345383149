import Image from "next/image";
import React from "react";

const Announcement = () => {
  return (
		<div className="m-0 p-0 flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
			{/* <div className="flex items-center space-x-4 mb-4 md:mb-0">
        <a
          href="https://instagram.com/chikitsa.io?igshid=NzZlODBkYWE4Ng=="
          className="mr-0"
        >
          <Image src="/svg/insta.svg" width={30} height={30} alt="Instagram" />
        </a>
        <a
          href="https://www.linkedin.com/company/vigorus-healthtech-private-limited-chikitsa/?originalSubdomain=in"
          className="mr-4"
        >
          <Image
            src="/svg/linkedin.svg"
            width={30}
            height={30}
            alt="LinkedIn"
          />
        </a>
        <a href="https://www.facebook.com/chikitsahealth" className="mr-4">
          <Image
            src="/svg/facebook.svg"
            width={30}
            height={30}
            alt="Facebook"
          />
        </a>
        <a href="https://twitter.com/chikitsahealth">
          <Image src="/svg/twitter.svg" width={27} height={27} alt="Twitter" />
        </a>
      </div> */}

			<div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
				<div className="flex items-center space-x-2 mb-4 md:mb-0">
					<Image src="/svg/mail.svg" alt="Mail" className="w-6 h-6" />
					<div className="ml-2">
						<span className="font-bold text-[#053C61] text-md"></span>{" "}
						<span>
							<a href="mailto:connect@chikitsa.io">connect@chikitsa.io</a>
						</span>
					</div>
				</div>

				<div className="flex items-center space-x-2">
					<Image src="/svg/phone.svg" alt="Phone" className="w-6 h-5" />
					<div className="ml-2">
						<span className="font-bold text-[#053C61] text-md"></span>{" "}
						<span>
							<a href="tel:+919509917266">+91 9509917266</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Announcement;

import { Alert, Snackbar } from "@mui/material";
import React from "react";

export default function SnackBar({ openToast, setOpenToast, isError }) {
  return (
    <Snackbar
      open={openToast}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={6000}
      onClose={() => setOpenToast(false)}
    >
      <Alert
        onClose={() => setOpenToast(false)}
        severity={isError}
        sx={{ width: "100%" }}
      >
        {openToast}
      </Alert>
    </Snackbar>
  );
}

import { Typography } from "@mui/material";
import React from "react";

export default function ErrorMessage({ text }) {
  return (
    <Typography
      color={"#d32f2f"}
      fontWeight={400}
      fontSize="0.75rem"
      lineHeight="1.66"
      letterSpacing="0.03333em"
      marginX="14px"
      marginTop="3px"
      textAlign="left"
    >
      {text}
    </Typography>
  );
}

export const validateScriptInput = (value) => {
	if (
		value?.includes("<") ||
		value?.includes(">") ||
		value?.includes("<script>") ||
		value?.includes("<script></script>") ||
		value?.includes("</script>")
	) {
		return true;
	} else {
		return false;
	}
};

import React, {
	useState,
	useRef,
	useEffect,
	useCallback,
	useContext,
} from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CachedIcon from "@mui/icons-material/Cached";
import IconButton from "@mui/material/IconButton";
// import TenantContext from "../../../../../context/TenantContext";
import Typography from "@mui/material/Typography";
import { useMediaQuery, useTheme } from "@mui/material";
import { validateScriptInput } from "@/utils/validateScriptInput";
export default function Captcha(props) {
	const {
		values,
		setValues,
		errors,
		touched,
		handleBlur,
		handleChange,
		setUserInput,
		setCaptchaResult,
		firstNumber,
		setFirstNumber,
		secondNumber,
		setSecondNumber,
		setFieldValue,
	} = props;
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.only("lg"));
	const canvasRef = useRef(null);

	const regenerateCaptcha = () => {
		setFirstNumber(Math.floor(Math.random() * 10));
		setSecondNumber(Math.floor(Math.random() * 10));
		setUserInput("");
		setFieldValue("captcha", "");
		drawCaptcha();
	};

	//   const validateCaptcha = () => {
	//     if (parseInt(userInput, 10) === captchaResult) {
	//       setCaptcha(true);
	//       regenerateCaptcha();
	//     } else {
	//       setCaptcha(false);
	//       regenerateCaptcha();
	//     }
	//   };

	// Function to draw the captcha on the canvas
	const drawCaptcha = useCallback(() => {
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");

		// Add background color
		context.fillStyle = "#f0f0f0";
		context.fillRect(0, 0, canvas.width, canvas.height);

		// Add text styles
		context.font = "bold 24px Impact";
		context.fillStyle = "rgba(180, 24, 37, .31)"; // Text color
		context.filter = "blur(0.5px)";

		// Add distortion
		context.setTransform(1.2, 0, 1, 1, 0, 1);
		context.fillText(`${firstNumber} + ${secondNumber} = ?`, 10, 30);

		// Add distortion
		context.setTransform(1.2, 0, 1, 1, 0, 1);

		// Add random lines for additional distortion
		for (let i = 0; i < 5; i++) {
			context.strokeStyle = "rgba(180, 24, 37, .31)";
			context.lineWidth = 1;
			context.beginPath();
			context.moveTo(Math.random() * canvas.width, 0);
			context.lineTo(Math.random() * canvas.width, canvas.height);
			context.stroke();
		}

		context.fillText(`${firstNumber} + ${secondNumber} = ?`, 10, 30);

		// Reset transform for future drawing
		context.setTransform(1, 0, 0, 1, 0, 0);
	}, [firstNumber, secondNumber]);

	// Draw the captcha when the component mounts
	useEffect(() => {
		setCaptchaResult(firstNumber + secondNumber);
		drawCaptcha();
	}, [firstNumber, secondNumber, drawCaptcha, setCaptchaResult]);
	return (
		<Box
			display="flex"
			flexDirection="row"
			gap={2}
			sx={{ mt: 1.2 }}
			flexWrap={matches ? "wrap" : "wrap"}
		>
			<canvas ref={canvasRef} width={150} height={40} />

			<Box display="flex" flexDirection="row" gap={2}>
				<TextField
					id="captcha"
					placeholder="Enter Captcha"
					variant="outlined"
					//   label="Captcha"
					name="captcha"
					value={values}
					error={touched.captcha && Boolean(errors.captcha)}
					helperText={touched.captcha && errors.captcha}
					onBlur={handleBlur}
					onKeyDown={(e) => {
						if (isNaN(Number(e.key)) && e.key !== "Backspace") {
							e.preventDefault();
						}
					}}
					onChange={(e) => {
						const newValue = e.target.value;
						if (newValue.length > 2) {
							return;
						}
						if (validateScriptInput(newValue)) {
							return;
						}
						handleChange(e);
					}}
					sx={{ backgroundColor: "white" }}
				/>

				<IconButton
					aria-label="captcha"
					sx={{ color: "black" }}
					onClick={regenerateCaptcha}
				>
					<CachedIcon />
				</IconButton>
			</Box>
		</Box>
	);
}
